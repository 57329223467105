// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import 'bootstrap';
import $, { ajax } from 'jquery';
global.$ = jQuery;
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css"

import "../stylesheets/application";

document.addEventListener("turbolinks:load", async function () {

  flatpickr(".datepicker", {
    "disable": [
        function(date) {
            // return true to disable
            var today = new Date();
            today.setHours(0,0,0,0);
            return ( date < today);
            //return (date.getDay() === 0 || date.getDay() === 1 || date < today);
        }
    ],
    "locale": {
        "firstDayOfWeek": 1 // start week on Monday
    }
  });

});



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
